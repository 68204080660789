import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import video from "../images/videoplayback2.mp4"

const GlobalStyle = createGlobalStyle`
  body {
	margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       }
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
`

const List = styled.ul`
  padding: 0;
  list-style: none;
`
const Li = styled.li`
  display: inline-block;
  padding: 10px 10px 0px 0px;
`
const Grid = styled.div`
  display: grid;
  height: 100vh;
  grid-template: 52px 1fr 100px / auto;
  background: #000;
  color: white;
  > header {
    grid-area: 1 / 1 / 2 / -1;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > main {
    grid-area: 2 / 1 / 3 / -1;
    z-index: 2;
    padding: 0 10% 200px;
    align-self: center;
  }
  > footer {
    grid-area: 3 / 1 / 4 / -1;
    text-align: center;
    z-index: 2;
  }
`
const Logo = styled.div`
  width: 100%;
`
const Nav = styled.div`
  width: 270px;
  margin-right: 20px;
`
const SocialNav = styled.div`
  width: 100%;
`
const Video = styled.video`
  grid-area: 1 / 1 / -1 /-1;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: fixed;
`
const Page = ({ location }) => (
  <Grid>
    <GlobalStyle />
    <header>
      <Logo>DT</Logo>
      <Nav>
        <List>
          <Li> About </Li>
          <Li> Projects </Li>
          <Li> Work </Li>
        </List>
      </Nav>
    </header>

    <Video preload="auto" autoPlay loop muted src={video}>
      {" "}
    </Video>

    <main>
      <h1> I am Dan Thompson </h1>
      <p> I make websites now </p>
    </main>

    <footer>
      <h3>Social Nav</h3>
      <SocialNav>
        <List>
          <Li>Facebook</Li>
          <Li>Twitter</Li>
          <Li>Instagram</Li>
        </List>
      </SocialNav>
    </footer>
  </Grid>
)

export default Page
